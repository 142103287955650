import React, { Component } from "react";
import { func } from "prop-types";
import { navigate } from "gatsby";
import { connect } from "react-redux";
import { Button } from "@instructure/ui-buttons";
import IconArrowOpenEnd from "@instructure/ui-icons/lib/IconArrowOpenEndSolid";
import IconArrowOpenStart from "@instructure/ui-icons/lib/IconArrowOpenStartSolid";

import Layout from "../../../components/layout";
import SEO from "../../../components/seo";
import styles from "./rnp-unsure.module.css";
import { setCurrentModule, setCurrentStep } from "../../../state/actions";

export class ReasonableAndPrudent extends Component {
  static propTypes = {
    handleNavigate: func,
    goToStep: func
  };

  componentDidMount() {
    // Make sure that the progress bar always shows
    this.props.handleNavigate(1);
    this.props.goToStep(6);
  }

  onSubmit = () => {
    this.props.goToStep(6, "/modules/normalcy/final-scenario");
  };

  goBack = () => {
    this.props.goToStep(6, "/modules/normalcy/high-risk-activity");
  };

  render() {
    return (
      <Layout isModule>
        <SEO
          title="Module - Normalcy - Reasonable & Prudent Parenting Standards"
          keywords={["utah foster care", "foster care"]}
        />
        <div className={styles.moduleGridContainer}>
          <p className={styles.openingParagraph}>
            <b>Ensure Safety!</b>
            <br />
            <br /> Foster parents should ensure{" "}
            <b>children follow all safety protocols</b> while participating in
            high risk activities.
            <br />
            <p>
              This includes:
              <ul className={styles.bullettedList}>
                <li>Wearing appropriate safety gear</li>
                <li>Understanding safety procedures</li>
                <li>Demonstrating safe behavior</li>
              </ul>
            </p>
          </p>
          <div className={styles.backButtonArea}>
            <Button
              onClick={this.goBack}
              variant="success"
              icon={IconArrowOpenStart}
            />
          </div>
          <div className={styles.forwardButtonArea}>
            <Button
              onClick={this.onSubmit}
              variant="success"
              icon={IconArrowOpenEnd}
            />
          </div>
        </div>
      </Layout>
    );
  }
}

const stateToProps = (state, ownProps) => ({
  ...ownProps
});

const dispatchToProps = dispatch => {
  return {
    handleNavigate(moduleId) {
      dispatch(setCurrentModule(moduleId));
    },
    goToStep(stepId, navigateTo) {
      dispatch(setCurrentStep(stepId));
      if (navigateTo) {
        navigate(navigateTo);
      }
    }
  };
};

export default connect(
  stateToProps,
  dispatchToProps
)(ReasonableAndPrudent);
